<template>
  <div class="bg">
    <div class="WordSection1">
      <p
        class="MsoNormal"
        style="margin-bottom: 12pt; text-align: center; line-height: normal"
      >
        <span
          lang="EN-IN"
          style="font-size: 30pt; font-family: 'Helvetica Neue'; color: #262626"
          >Moonfrog Redemption Terms</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 30pt; text-align: justify; line-height: normal"
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
        >
          Last Updated : 20/10/2022
        </span>
      </p>
      <div>
        <h2>Legal Disclaimer:</h2>
        <ol>
          <li>
            Moonfrog Games Gift Code purchases are non-refundable. Moonfrog
            games Gift Codes can only be used on the Moonfrog redemption portal
            to purchase moonfrog credits, in game coins/chips/gems/accessories,
            digital content and in-app items available for the game
            <br />
            <i>
              * Only use this gift card's code on Moonfrog Redemption Platform .
              Any other request for the code may be a scam. Visit
              https://distribution.moonfroglabs.com/redeem
            </i>
          </li>

          <li>
            Moonfrog games are purely free-to-play and do not have any real
            money engagement . Moonfrog does not allow monetisation or cash-out
            of game currency/credits in any form for any of our games
          </li>
          <li>
            <b> Terms and Conditions: </b>
            See www.moonfroglabs.com for full terms. Issued by Moonfrog Labs Pvt
            Ltd. for purchases of eligible items on Moonfrog Games only. Users
            must be Indian residents aged 18+. Between the ages of 13 and 17,
            the consent of a parent or guardian is required prior to making a
            purchase. Requires internet access to redeem on Moonfrog Redemption
            Platform. Not usable for hardware and certain subscriptions. Other
            limits may apply. Check for fees or expiration. Card not redeemable
            for cash or other cards, not reloadable or refundable and cannot be
            combined with other moonfrog game balances, resold, exchanged or
            transferred for value. User is responsible for loss of card. For
            help, Visit www.moonfroglabs.com
          </li>
          <li>
            VALIDITY - 365 DAYS FROM DATE OF ACTIVATION/GENERATION of voucher
            code/PIN
          </li>
          <li>
            This code is issued and is the property of by Moonfrog labs Private
            Limited India, a Stillfront Group PUBL, Sweden , Company
          </li>
          <li>This code can only be redeemed in India</li>
          <li>
            For the extra gaming offer, the user needs to purchase the
            respective pack to get the benefit of additional benefits
          </li>
          <li>
            This code is non-refundable and cannot be transferred for value or
            redeemed for cash. It is not a credit/debit code. This code is not a
            legal tender & does not entail any civil/criminal liability on
            Moonfrog Labs
          </li>
          <li>
            Moonfrog Labs Private Limited India shall not be held responsible
            for any misuse, theft, or transfer that occurred after the purchase
            of the code.
          </li>
          <li>
            Any dispute arising with respect to the code will be subject to the
            exclusive jurisdiction of competent courts in Bangalore The
            existence of any dispute if any will not constitute any claim
            towards Moonfrog Labs.
          </li>
          <li>
            Use of this code constitutes acceptance of the terms and conditions
            as available on https://moonfroglabs.com/terms-of-service/
          </li>
          <li>
            No returns and no refunds on gift cards, E- gift cards and gift
            vouchers shipped
          </li>
          <li>
            The prepaid code is for one time redemption only, in case of partial
            redemption left over amount can be utilised for purchase of other
            packs. Multiple prepaid codes cannot be applied in a single
            transaction. No cash will be added to any wallet.
          </li>
        </ol>
      </div>
      <hr />
      <h2 style="text-align: justify">
        <span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          Steps to Redeem Voucher
        </span>
      </h2>
      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 12pt;
          margin-left: 0in;
          text-align: justify;
        "
      >
        <span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          <ol>
            <li>Player should navigate to the <a href="https://distribution.moonfroglabs.com/redeem"> redeem link </a></li>
            <li>Player needs to select <u><i> voucher source </i></u> </li>
            <li>Player has to enter the pid.</li>
            <li>Again needs to enter the same pid to confirm.</li>
            <li>Player can enter the voucher code and click on redeem.</li>
            <li>Player gets purchase successful pop up.</li>
            <li>
              Player can then launch the app and can check for rewards add to
              his wallet.
            </li>
          </ol>
        </span>
      </p>

      <h2 style="text-align: justify">
        <span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          Terms and Conditions
        </span>
      </h2>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 12pt;
          margin-left: 0in;
          text-align: justify;
        "
      >
        <span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          <ol>
            <li>
              Vouchers can be used only for Teen Patti Gold or Ludo Club or
              Carrom Gold players.
            </li>
            <li>Vouchers can’t be redeemed more than once.</li>
            <li>
              Vouchers once redeemed can’t be undone. So make sure you enter the
              correct Player ID details.
            </li>
            <li>Vouchers can’t be redeemed after its expiry date.</li>
            <li>
              All the service related to Moonfrog games is subject to terms and
              conditions available on:
            </li>
            <ol>
              <li>
                <a href="https://www.moonfroglabs.com/terms-of-service/">
                  <span style="color: #3cb2e2">Terms of Service</span>
                </a>
              </li>
              <li>
                <a href="https://teenpattigold.com/rulesnew.html">
                  <span style="color: #3cb2e2">Rules</span>
                </a>
              </li>
            </ol>
          </ol>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "redemptionTnC",
  components: {},
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.bg {
  background: white;
  min-height: 100vh;
  max-width: none;
  padding: 2rem;
}

/* Font Definitions */

@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}

@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}

@font-face {
  font-family: "Helvetica Neue";
}

/* Style Definitions */

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 8pt;
  margin-left: 0in;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}

h1 {
  mso-style-link: "Heading 1 Char";
  margin-right: 0in;
  margin-left: 0in;
  font-size: 24pt;
  font-family: "Times New Roman", serif;
  font-weight: bold;
}

h2 {
  mso-style-link: "Heading 2 Char";
  margin-right: 0in;
  margin-left: 0in;
  font-size: 18pt;
  font-family: "Times New Roman", serif;
  font-weight: bold;
}

p.MsoBodyText,
li.MsoBodyText,
div.MsoBodyText {
  mso-style-link: "Body Text Char";
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 7.6pt;
  text-autospace: none;
  font-size: 10pt;
  font-family: "Calibri", sans-serif;
}

a:link,
span.MsoHyperlink {
  color: blue;
  text-decoration: underline;
}

p {
  margin-right: 0in;
  margin-left: 0in;
  font-size: 12pt;
  font-family: "Times New Roman", serif;
}

p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 7.6pt;
  text-align: justify;
  text-autospace: none;
  font-size: 12pt;
  font-family: "Calibri", sans-serif;
}

span.Heading1Char {
  mso-style-name: "Heading 1 Char";
  mso-style-link: "Heading 1";
  font-family: "Times New Roman", serif;
  font-weight: bold;
}

span.Heading2Char {
  mso-style-name: "Heading 2 Char";
  mso-style-link: "Heading 2";
  font-family: "Times New Roman", serif;
  font-weight: bold;
}

span.BodyTextChar {
  mso-style-name: "Body Text Char";
  mso-style-link: "Body Text";
  font-family: "Times New Roman", serif;
}

.MsoChpDefault {
  font-family: "Calibri", sans-serif;
}

@page WordSection1 {
  size: 595.3pt 841.9pt;
  margin: 1in 1in 1in 1in;
}

div.WordSection1 {
  page: WordSection1;
}

/* List Definitions */

ol {
  margin-bottom: 0in;
}

ul {
  margin-bottom: 0in;
}
</style>
